import React, {FC, PropsWithChildren, useMemo, useEffect} from 'react';
import {LinkContainer} from 'react-router-bootstrap';
import {Link} from 'react-router-dom';
import {Nav, Navbar, Container, Dropdown} from 'react-bootstrap';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import {useTranslation} from 'react-i18next';
import {useEcommerce} from '../../contexts/EcommerceContext';
import logoPlaceholder from '../../assets/logo.svg';
import {useDeviceWindow} from '../../contexts/DeviceWindowContext';
import OrderSummary from '../../components/OrderSummary/OrderSummary';
import {useSession} from '../../contexts/SessionContext';
import {
  customerAllowedToCheckout,
  cartMeetsMinimumPurchase,
  customerAllowedToSeePrices,
} from '../../utils';

import styles from './StorePage.module.scss';

type CallToActionButtonProps = {
  to: string;
  icon?: IconProp;
};

const CallToActionButton: FC<PropsWithChildren<CallToActionButtonProps>> = ({
  to,
  icon,
  children,
}) => {
  const {isMobile} = useDeviceWindow();

  return (
    <LinkContainer to={to} className={styles.callToAction}>
      <Nav.Link>
        {!isMobile && icon ? <Icon icon={icon} /> : null}
        {children}
      </Nav.Link>
    </LinkContainer>
  );
};

const UserMenu = () => {
  const [sessionState] = useSession();
  const {t} = useTranslation('pages');

  const user =
    sessionState?.data?.user?.info?.fullname || sessionState?.data?.user?.username || null;

  return (
    <Dropdown as={Nav.Item} className={styles.menu}>
      <Dropdown.Toggle as={Nav.Link}>
        <Icon icon={['fas', 'circle-user']} />
      </Dropdown.Toggle>
      <Dropdown.Menu align="end">
        {user ? <p>{t('StorePage.StoreNavbar.UserMenu.message', {user})}</p> : null}
        <Dropdown.Item as={Link} to="/user/information">
          <Icon icon={['fas', 'user']} />{' '}
          {t('StorePage.StoreNavbar.UserMenu.links.labels.information')}
        </Dropdown.Item>
        <Dropdown.Item as={Link} to="/user/orders">
          <Icon icon={['fas', 'bag-shopping']} />{' '}
          {t('StorePage.StoreNavbar.UserMenu.links.labels.orders')}
        </Dropdown.Item>
        <Dropdown.Item as={Link} to="logout">
          <Icon icon={['fas', 'right-from-bracket']} /> Logout
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

const UserLogin = () => {
  const [, , selectors] = useSession();
  const [state] = useEcommerce();
  const {t} = useTranslation('pages');

  const anonymousRestrictions = state.settings.COMPANY_ANONYMOUS_CUSTOMER_RESTRICTIONS;

  useEffect(() => {
    const scriptId = 'hs-script-loader';
    const hubspotId = state.settings.COMPANY_HUBSPOT;

    const existingScript = document.getElementById(scriptId);
    if (existingScript) {
      existingScript.remove();
    }

    if (hubspotId) {
      const newScript = document.createElement('script');
      newScript.type = 'text/javascript';
      newScript.id = scriptId;
      newScript.async = true;
      newScript.defer = true;
      newScript.src = `//js.hs-scripts.com/${hubspotId}.js`;

      document.body.appendChild(newScript);
    }
  }, [state.settings.COMPANY_HUBSPOT]);

  if (anonymousRestrictions === 'ANONYMOUS_ONLY') {
    return null;
  }

  if (selectors.isAnonymousCustomerSession) {
    return (
      <CallToActionButton to="login" icon="circle-user">
        {t('StorePage.StoreNavbar.LogIn')}
      </CallToActionButton>
    );
  }

  return <UserMenu />;
};

const MenuDesktop = () => {
  const [state] = useEcommerce();
  const [, , selectors] = useSession();
  const {t} = useTranslation('pages');

  const allowCheckout =
    customerAllowedToCheckout(state.settings, selectors) && cartMeetsMinimumPurchase(state);

  const showPrices = customerAllowedToSeePrices(state.settings, selectors);

  const phoneNumber =
    state.seller?.user_phone || state.settings.COMPANY_PHONE_NUMBER_OPERATION || null;

  const title = useMemo(
    () =>
      state.settings.COMPANY_STORE_INFO_TITLE
        ? state.settings.COMPANY_STORE_INFO_TITLE.charAt(0).toUpperCase() +
          state.settings.COMPANY_STORE_INFO_TITLE.slice(1).toLowerCase()
        : t('StorePage.StoreNavbar.whoWeAre'),
    [state.settings.COMPANY_STORE_INFO_TITLE, t],
  );

  return (
    <Nav>
      {state.settings.COMPANY_WHO_WE_ARE && (
        <LinkContainer to="who-we-are/">
          <Nav.Link>
            <Icon icon={['fas', 'user-group']} />
            {title}
          </Nav.Link>
        </LinkContainer>
      )}
      <LinkContainer to="favorites/">
        <Nav.Link>
          <Icon icon={['far', 'heart']} />
          {t('StorePage.StoreNavbar.favorites')}
        </Nav.Link>
      </LinkContainer>
      <OrderSummary
        subtotal={state.cart.subtotal ?? '0'}
        discounts={state.cart.discounts ?? '0'}
        quantity={state.cart.quantity ?? 0}
        total={state.cart.total ?? '0'}
        currency={state.settings.COMPANY_CURRENCY}
        priceList={state.priceList?.label}
        taxesEnabled={state.settings.COMPANY_PRICE_WITH_TAXES}
        fromEcommerce={{
          allowCheckout,
          showPrices,
          minimumPurchase: state.settings.COMPANY_MINIMUM_PURCHASE,
        }}
        companyDiscounts={state.settings.COMPANY_AMOUNT_DISCOUNT}
        styleProp={styles.cart}
      />
      <UserLogin />
      {state.settings.COMPANY_ALLOW_REGISTER_USER && selectors.isAnonymousCustomerSession ? (
        <CallToActionButton to="register" icon="circle-plus">
          {t('StorePage.StoreNavbar.create_account')}
        </CallToActionButton>
      ) : null}
      {phoneNumber && state.settings.COMPANY_PHONE_NUMBER_OPERATION_ALWAYS_SHOW && (
        <Nav.Link
          href={`https://api.whatsapp.com/send?phone=${phoneNumber}&text=Estoy%20mirando%20la%20web%20y%20quería%20hacer%20una%20consulta`}
          target="_blank"
          className={styles.whatsapp}
        >
          <Icon icon={['fab', 'whatsapp']} />
        </Nav.Link>
      )}
    </Nav>
  );
};

const MenuMobile = () => {
  const [, , selectors] = useSession();
  const [state] = useEcommerce();

  const {t} = useTranslation('pages');

  const phoneNumber =
    state.seller?.user_phone || state.settings.COMPANY_PHONE_NUMBER_OPERATION || null;

  return (
    <Nav>
      {state.settings.COMPANY_WHO_WE_ARE && (
        <LinkContainer to="who-we-are/">
          <Nav.Link>
            <Icon icon={['fas', 'user-group']} />
            {t('StorePage.StoreNavbar.whoWeAre')}
          </Nav.Link>
        </LinkContainer>
      )}
      <LinkContainer to="favorites/">
        <Nav.Link>
          <Icon icon={['far', 'heart']} />
          {t('StorePage.StoreNavbar.favorites')}
        </Nav.Link>
      </LinkContainer>
      {state.settings.COMPANY_ALLOW_REGISTER_USER && selectors.isAnonymousCustomerSession ? (
        <CallToActionButton to="register" icon="circle-plus">
          {t('StorePage.StoreNavbar.create_account')}
        </CallToActionButton>
      ) : null}
      {phoneNumber && state.settings.COMPANY_PHONE_NUMBER_OPERATION_ALWAYS_SHOW ? (
        <Nav.Link
          href={`https://api.whatsapp.com/send?phone=${phoneNumber}&text=Estoy%20mirando%20la%20web%20y%20quería%20hacer%20una%20consulta`}
          target="_blank"
        >
          <Icon icon={['fab', 'whatsapp']} />
          {t('StorePage.StoreNavbar.contactUs')}
        </Nav.Link>
      ) : null}
    </Nav>
  );
};
const StoreNavbar = () => {
  const [state] = useEcommerce();
  const [, , selectors] = useSession();
  const {isMobile} = useDeviceWindow();

  const allowCheckout =
    customerAllowedToCheckout(state.settings, selectors) && cartMeetsMinimumPurchase(state);

  const showPrices = customerAllowedToSeePrices(state.settings, selectors);

  return (
    <Navbar
      sticky="top"
      bg="white"
      variant="light"
      className={styles.navbar}
      expand="lg"
      collapseOnSelect
    >
      <Container fluid className="px-lg-4 px-xs-2">
        <LinkContainer key="home" to="/">
          <Navbar.Brand>
            <img
              src={state.settings.COMPANY_THEME_LOGO || logoPlaceholder}
              alt="company-logo"
              className="logo"
            />
          </Navbar.Brand>
        </LinkContainer>
        <div className="d-flex justify-content-end align-items-center">
          {isMobile ? (
            <React.Fragment>
              <OrderSummary
                subtotal={state.cart.subtotal ?? '0'}
                discounts={state.cart.discounts ?? '0'}
                quantity={state.cart.quantity ?? 0}
                total={state.cart.total ?? '0'}
                currency={state.settings.COMPANY_CURRENCY}
                taxesEnabled={state.settings.COMPANY_PRICE_WITH_TAXES}
                priceList={state.priceList?.label}
                fromEcommerce={{
                  allowCheckout,
                  showPrices,
                  minimumPurchase: state.settings.COMPANY_MINIMUM_PURCHASE,
                }}
                companyDiscounts={state.settings.COMPANY_AMOUNT_DISCOUNT}
                styleProp={styles.cart}
              />
              <UserLogin />
            </React.Fragment>
          ) : null}
          <Navbar.Toggle aria-controls="navbar" />
        </div>
        <Navbar.Collapse id="navbar" className="justify-content-end">
          {isMobile ? <MenuMobile /> : <MenuDesktop />}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default StoreNavbar;
